.login-log {
  text-align: left;
  padding-bottom: 10px;
}

.login-log img {
  max-width: 200px;
}

.auth-login-icon {
  border: 1px solid #9ca3af;
  border-radius: 4px;
  margin-top: 10px;
  margin-top: 20px;
  padding: 4px;
  text-align: center;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
}

.auth-login-icon span {
  font-weight: 700 !important;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #171717;
  font-size: 16px;
}

.googleicon {
  max-width: 100%;
  text-align: center;
}

.googleicon button,
.facebookicon button {
  border: 0px !important;
  box-shadow: none !important;
  background-color: transparent;
  font-size: 14px;
}

.facebookicon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  margin-left: -10px;
}

.facebookicon img {
  max-width: 50px;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 14px;
  padding-top: 5px;
  color: #6b7280;
  margin: 0;
}

.forgot-password a {
  color: #9ca3af;
  text-decoration: none;
}

.form-select {
  font-size: 14px !important;
  height: 40px;
}

.form-control {
  border: 1px solid #9ca3af;
  border-radius: 4px !important;
  height: 40px;
  color: #171717 !important;
  font-weight: 500;
  font-size: 14px !important;
}

.login-checkbox {
  margin-right: 10px;
  font-size: smaller;
}

.checkbox-label {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-size: 14px;
  color: #9ca3af;
}

.sub-title {
  font-weight: 600 !important;
}

.btn {
  color: #ffffff !important;
  font-weight: 600 !important;
  font-size: 16px !important;
}

.btn-primary {
  border-radius: 4px !important;
  background: #9ca3af !important;
  font-size: 14px !important;
  border-color: #9ca3af !important;
  padding-left: 20px;
  padding-right: 20px;
}
.btn-primary-login {
  border-radius: 4px !important;
  background: #9ca3af !important;
  font-size: 16px !important;
  border-color: #9ca3af !important;
  padding-left: 20px;
  padding-right: 20px;
}

.btn-secondary {
  border-radius: 4px !important;
  background: #797a7e !important;
  font-size: 14px !important;
  border-color: #797a7e !important;
  /* padding-left: 20px;
    padding-right: 20px; */
}

.new-create-account {
  text-align: center;
  margin-top: 16px;
}

.new-create-account p,
.new-create-account p a {
  font-size: 16px;
}

.new-create-account a {
  color: #9ca3af;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 16px;
  color: #9ca3af;
}

.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #9ca3af;
}

.separator:not(:empty)::before {
  margin-right: 0.25em;
}

.validation-error {
  color: crimson;
}

.separator:not(:empty)::after {
  margin-left: 0.25em;
}

.btn[disabled] {
  background-color: #e9e9e9 !important;
  color: #171717 !important;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 16px !important;
  font-weight: 600 !important;
  border-color: #e9e9e9 !important;
}

.cont-s {
  font-size: 12px;
  font-weight: 600;
  font-family: "Plus Jakarta Sans", sans-serif;
  text-align: center;
}

.custom-control-label {
  font-weight: 400;
  font-size: 16px;
}

.default-inner h3 {
  font-size: 24px;
}

.password-cont {
  position: relative;
}

.show-password {
  position: absolute;
  right: 35px;
  top: 15px;
  color: #999;
}

.contanier-type2 {
  width: 500px;
  margin: auto;
  padding: 25px;
  padding-bottom: 0px;
}

.center-title {
  text-align: center;
  margin-bottom: 50px;
}

.contanier-type2 h2 {
  font-size: 32px;
  font-weight: 600;
}

.contanier-type2 .sub-title {
  font-size: 14px;
  color: #393939;
}

.contanier-type2 h3 {
  font-size: 20px;
  font-weight: 600;
}

.contanier-type2 p {
  font-size: 14px;
}

.onboard-cont {
  border: 1px #9ca3af solid;
  border-radius: 12px;
  padding: 20px 30px;
  margin: 10px auto;
}

.onboard-warring {
  border: 1px #ffbd06 solid;
  background-color: #fffef5;
}

.onboard-success-btn button {
  width: 100%;
  font-size: 16px;
  font-weight: 700;
}

.onboard-success-btn button.explore {
  border: 1px #9ca3af solid;
  background-color: #ffffff !important;
  color: #9ca3af !important;
}

.form-label {
  font-weight: 550 !important;
  font-size: 15px;
}

.login-form html {
  margin-left: 0px !important;
}

.contanier-type1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
}

.white-box {
  background-color: white !important;

  border-radius: 7px;
  width: 450px !important;
  padding: 30px;

  margin-left: 10px;
  margin-right: 10px;
}
@media screen and (max-width: 360px) {
  .white-box {
    padding: 10px !important;
  }
}
.box-body {
  width: 100%;
  display: flex;
  justify-content: center;
}
.maincont {
  margin-left: 70px;
}

.spinner-box {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

/* PULSE BUBBLES */
.pulse-container {
  width: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pulse-bubble {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #ffffff;
}

.pulse-bubble-1 {
  animation: pulse 0.4s ease 0s infinite alternate;
}

.pulse-bubble-2 {
  animation: pulse 0.4s ease 0.2s infinite alternate;
}

.pulse-bubble-3 {
  animation: pulse 0.4s ease 0.4s infinite alternate;
}

@keyframes pulse {
  from {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: 0.25;
    transform: scale(0.75);
  }
}
.form-control.is-valid,
.was-validated .form-control:valid {
  border: #9ca3af 1px solid;
  outline: none !important;
}
.was-validated .form-control:valid {
  border-color: black;
  padding-right: calc(1.5em + 0.75rem);
  background-image: none;
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid {
  border: #9ca3af 1px solid;
}
.form-control {
  outline: none !important;
  box-shadow: none !important;
}
.custom-input:focus {
  outline: none;
  box-shadow: none;
  border-color: #9ca3af !important;
}

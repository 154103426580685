@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@400;600;700&display=swap");

* {
  font-family: "Titillium Web", sans-serif;
  box-sizing: border-box;
}

h5 {
  font-weight: 600;
}

main {
  padding: 35px;
}

.mainpage {
  margin-top: 50px;
}

.css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: #9ca3af !important;
  color: white !important;
  border-color: #9ca3af !important;
}

.css-2gftvx.Mui-selected {
  background-color: #9ca3af !important;
  color: white !important;
  border-color: #9ca3af !important;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  background-color: #ffffff;
  color: white;
  z-index: 10;
}

.page-title {
  font-family: "Plus Jakarta Sans";
  font-size: 20px;
  color: #111827;
}

.maincont nav {
  background-color: #ffffff !important;
  height: 70px;
  color: #9ca3af;
  border: 1px #f3f4f6 solid;
  position: fixed;
  width: 100%;
  top: 0;
  right: 0;
  padding-left: 80px;
  z-index: 10;
}

.maincont .table-footer-right nav {
  background-color: #ffffff !important;
  height: 70px;
  color: #9ca3af;
  border: 1px #f3f4f6 solid;
  position: relative !important;
  width: 100%;
  top: 0;
  right: 0;
  padding-left: 80px;
  z-index: 1;
}

.maincont .nav-link {
  padding-top: 0px;
  padding-bottom: 0px;
}

.nav-link,
.dropdown-item {
  font-family: "Averta", sans-serif;

  color: #6b7280 !important;
}

.dropdown-item:hover {
  background-color: #9ca3af;
  height: 100%;
  color: white !important;
}

.dropdown-menu[data-bs-popper] {
  right: 0;
  left: auto !important;
}

.header-search {
  background-color: #f3f4f6;
  border-radius: 4px;
}

.header-search button,
.header-search input {
  background-color: #f3f4f6;
  border: 0px;
  height: 40px;
  border-radius: 4px;
}

.header-search button {
  padding: 0 12px;
}

.header-search input {
  width: 300px;
  color: #9ca3af !important;
  outline: none;
}

.header-search button i {
  color: #9ca3af;
}

.profile-icon {
  position: relative;
  color: #6b7280;
}

.profile-icon .profile-img {
  display: inline-block;
  border-radius: 50%;
  margin-right: 10px;
  background-color: #9ca3af;
}

.profile-icon i {
  position: absolute;
  bottom: -5px;
  left: 27px;
}

.profile-icon span {
  padding-left: 10px;
}

.dropdown-toggle::after {
  content: none !important;
}

.maincont .nav-item.dropdown {
  margin: 0px !important;
}

.custom-switch-container {
  cursor: default;
}

.form-switch .form-check-input {
  width: 40px !important;
  height: 24px !important;
  cursor: pointer;
  background-color: #4b5563 !important;
}

.form-switch .form-check-input:checked {
  border-color: #9ca3af !important;
  background-color: #9ca3af !important;
}

.hr-line-container {
  margin-left: 23px;
  width: 30%;
  border-bottom: 1px solid #ffffff;
  /* You can adjust the color and thickness of the line here */
  margin-bottom: 10px;
  /* Optional: Add margin at the bottom of the line */
}

.logosmall {
  width: 30px;
}

.table thead th {
  font-size: 16px;
  color: rgb(49, 45, 45);
  font-weight: 600;
  border: 1px solid #dee2e6 !important;
}

.table tbody td {
  border: 1px solid #dee2e6 !important;
  font-size: 14px;
  font-weight: 500;
  color: #000000;
}

.logosmalls {
  float: left;
}

.buttonedit .edit-button {
  margin-right: 5px;
}

.focused {
  position: absolute;
  pointer-events: none;
  top: 0;
  transition: 0.2s ease all;
  transform: translateY(-50%);
  font-size: 14px;
  color: #000;
  /* Change the color as needed */
}

.focused-input {
  border: 0;
  border-bottom: 1px solid #000;
  /* Change the color as needed */
  outline: 0;
  transition: border-color 0.2s;
}

.menu-items img {
  margin-left: 8px;
}

.inputs {
  display: flex;
  justify-content: space-between;
}

.vendor-dropdown {
  border: 1px solid black;
}

#triggerId {
  border: 1px solid #ced4da;
  margin-left: 1rem;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  line-height: 1.5;
  width: 207px;
  height: 50px;
  background-color: white;
  font-size: 15px;
  color: #495057 !important;
  align-items: start;
  font-weight: 500;
  font-size: 15px;
}

#triggerIds {
  border: 1px solid #ced4da;

  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  line-height: 1.5;
  width: 220px;
  height: 50px;
  background-color: white;
  font-size: 15px;
  color: #495057 !important;
  align-items: start;
  font-weight: 500;
  font-size: 15px;
}

#arrorw {
  font-size: 12px;
}

.bg-body-tertiary {
  background-color: white !important;
  border-bottom: 1px solid #f3f4f6;
}

.footer a {
  text-decoration: none !important;
  color: black;
  font-size: 11px;
}

.footer {
  background-color: white;
  padding-top: 5px;
  padding-bottom: 5px;

  padding-top: 10px;
  border-top: 1px solid #f3f4f6;
  font-size: 11px;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  bottom: 0;
  width: 100%;
}

.edit-showPassword {
  position: absolute;
  z-index: 100;
  color: #999;
  right: 25px;
  top: 110px;
}

.selectButtons {
  justify-content: space-between;
  padding-bottom: 10px;
  width: 70%;
  margin-left: 6%;
}

.selectButton {
  justify-content: space-between;
  padding-bottom: 10px;
  width: 70%;
  margin-left: 39%;
}

.selectBut {
  justify-content: space-between;
  padding-bottom: 10px;
  width: 70%;
  margin-left: 32.2%;
}

.drop {
  /* width: 180px; */
  color: black !important;
  border: 1px solid #808080 !important;
}

.drop:hover {
  background-color: #9ca3af;
  color: white !important;
}

.dropType {
  width: 280px;
  color: black !important;
  border: 1px solid #808080 !important;
}

.dropType:hover {
  background-color: #9ca3af;
  color: white !important;
}

.navbar-expand-lg .navbar-nav {
  position: absolute;
  right: 10px;
  z-index: 1;
  flex-direction: row;
}
.navbar-expand-lg .navbar-nav .dropdown-menu {
  position: absolute;
  margin: 0;
  padding: 0;
  gap: 0;
}

.navbar-toggler {
  margin-right: -20px;
}

.menu-value {
  background-color: white !important;
}

.navbar-collapse.collapse.show .menu-value {
  background-color: #9ca3af !important;
}

.navbar-collapse.collapse.show .menu-value .profile-icon {
  color: white !important;
}

.navbar-collapse.collapse.show .menu-value .bell .bell-image {
  filter: invert(100%);
  padding-left: 25px;
}

.navbar-toggler:focus {
  position: absolute;
  right: 100px;
}

.navbar-collapse::after .navbar-nav {
  background-color: #9ca3af;
}

#trigger:hover {
  background-color: #0000003b !important;
}

#trigger {
  position: absolute;
  top: 110px;
  right: 40px;
}

.logo {
  margin-left: 20px;
  width: 50px;
  margin-top: 40px;
}

.content {
  margin: auto;
  padding: 30px 30px 0;
  width: 400px;
}

.spin {
  position: fixed;
  top: 60%;
  left: 50%;
  color: red;
}

.custom-container {
  margin-top: 50px;
}

.custom-dropdown-toggle {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.custom-dropdown-toggle #arrow {
  margin-left: auto;
}

.top-box {
  align-items: center;
  justify-content: center;

  margin-top: 20px;
}

.box {
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 18px;
  height: 90px;
  width: 150px;
  border-radius: 10px;
  padding: 15px;
  margin-left: 15px;
}

.submit {
  border-radius: 10px;
  background-color: white !important;
  color: black !important;
  border-color: White !important;
  height: 37px !important;
  margin-top: 20px !important;
}

.dropdown-menu.show {
  display: block;
  max-height: 200px;
  overflow-y: auto;
}

.active > .page-link {
  background-color: #9ca3af !important;
  border-color: #9ca3af !important;
}

.page-link {
  color: #797a7e;
}

svg {
  height: 15px !important;
}

.MuiDataGrid-cellCheckbox {
  width: 10px !important;
}

/* .MuiDataGrid-cell {
  align-items: center !important;
  justify-content:start  ;
  display: flex !important;
} */

.zero-quantity-row {
  background-color: #6b7280 !important;
}

.nozero-quantity-row {
  background-color: #e2f8e8 !important;
}

.img-col {
  width: 180px !important;
}

.import {
  display: flex;
  justify-content: center;
}

:where(.css-dev-only-do-not-override-amq5gd).ant-upload-wrapper
  .ant-upload-drag {
  background-color: #6b7280 !important;
  border-color: #6b7280;
}

.custom-file-button input[type="file"]::file-selector-button {
  position: absolute;
  top: 7.35px;
  right: 0px;
  margin-left: 0px !important;
  background-color: #9ca3af;
  color: white;
  display: " var(--#{$prefix}secondary-bg)";
}

.custom-file-button input[type="file"]:hover::file-selector-button {
  background-color: #9ca3af !important;
  color: white !important;
}

.fileSelect {
  position: relative !important;
}

#file-upload {
  height: 37px !important;
}

.form-control:focus {
  border: 1px solid #ffdbdb !important;
  box-shadow: none !important;
}

option:hover {
  background: red !important;
}

.form-select:focus {
  box-shadow: none !important;
  border: 1px solid #ffdbdb !important;
}

.form-check-input:focus {
  box-shadow: none !important;
  border-color: #e5e7eb;
}
@media (min-width: 1200px) {
  .main-table {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media (max-width: 800px) {
  .btn-primary {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.centers {
  display: flex;
  justify-content: center;
  align-items: center;
}
.remove-bullet {
  list-style-type: none;
}
.responsive-text {
  font-size: clamp(2rem, 5vw, 1rem);
}

.login-form-full-width {
  width: 100%;
}

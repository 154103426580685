.header {
  height: 56px;
  background-color: #00c853;
  position: fixed;
  z-index: 1;
}
.layout {
  padding-top: 20px;
  height: 100vh;
  width: 250px;
  background-color: #9ca3af;
}
.plus {
  color: var(--basetheme);
  font-size: 1.3rem;
}
.brand-title {
  font-family: "albert light", sans-serif;
  color: var(--basetheme);
  font-size: 2rem;
  background-color: var(--basetheme);
  display: none;
  font-weight: bolder;
}
.toggle {
  z-index: 2;
  position: fixed;
  top: 18px;
  left: 20px;
  color: white;
  font-size: 1.3rem;
  cursor: pointer;
}

.list-name {
  width: 250px;
  font-family: "albert light", sans-serif;
  list-style: none;
  padding: 13px 15px;
  margin: 0px 15px;
  transition: width 0.3s ease;
}
.active {
  color: var(--activecolor) !important;
  text-shadow: none !important;
}

.route-link {
  cursor: pointer;
  text-decoration: none;
  color: var(--fontcolor);
}

.layout-fixed.collapsed {
  max-width: 0;
}
.input-box {
  height: 42px;
  border-radius: 5px;
  padding-left: 40px;
  width: 100%;
  background-color: var(--basethemelight);
  border: 0.5px solid var(--basethemelight) !important;
  box-shadow: none !important;
  color: white;
}
.input-box:focus {
  outline: none !important;
  box-shadow: none !important;
}
.input-box::placeholder {
  color: white;
}
.input-search {
  display: flex;
  flex-grow: 1;
  position: relative;
}
.sub-drop {
  color: white;
  position: absolute;
  top: 8px;
  right: 10px;
}
.layout-fixed {
  height: 100vh;
  width: 0;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  margin-top: 56px;
  transition: 0.5s;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.layout-fixed::-webkit-scrollbar {
  width: 3px;
}

.layout-fixed::-webkit-scrollbar-thumb {
  background-color: var(--bordercolor);
}

.layout-fixed::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
.layout-fixed.open {
  width: 250px;
}

.layout-fixed .closebtn {
  align-self: flex-start;
  font-size: 36px;
  color: #818181;
  cursor: pointer;
}

.outlet-layout {
  transition: margin-left 0.5s;
  margin: 4.5rem 0px;
}

.outlet-layout.shifted {
  margin-left: 250px;
}
.icon-items {
  font-size: 14px;
}

.icon-button {
  display: flex;
  text-align: center;
}
.circle {
  position: absolute;
  background-color: red;
  color: white;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  top: 2px;
  left: 20px;
}
.logout-option {
  position: absolute;
  right: 0;
  border-radius: 5px;
  background-color: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.profile-text {
  font-family: "albert light", sans-serif;
  font-size: 10px;
  margin: 0px 5px;
}
.contact-query {
  cursor: pointer;
  position: fixed;
  bottom: 5px;
  left: 20px;
  z-index: 1;
}
.logout-loading {
  position: absolute;
  right: 50%;
  top: 50%;
}
.logout-popup {
  position: fixed;
  display: block;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3;
  cursor: pointer;
  text-align: center;
}

@media screen and (max-width: 750px) {
  /* .icon-button {
      display: none;
    } */
  .input-search {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .layout-fixed.open {
    width: 250px;
    background-color: white;
  }
  .outlet-layout.shifted {
    margin-left: 0px;
  }
}
.toggle {
  z-index: 10;
  position: fixed;
  top: 15px;
  left: 20px;
  color: #6b7280;
  font-size: 1.3rem;
  cursor: pointer;
}
/* Sidebar.css */

.sidebar {
  background-color: #797a7e;
  position: fixed;
  list-style: none !important;
  top: 0;
  bottom: 0;
  left: 0;
  min-height: 100vh !important;
  z-index: 100;
  overflow: hidden;
  font-family: var(--bs-font-sans-serif);
  display: block;
}

.side .brand-logo .logolarge {
  display: block;
  color: white;
}

.menus {
  padding-left: 0px;
  margin-bottom: 5px;
}

.menus.bottom {
  position: absolute;
  bottom: 0px;
}

.menu-item {
  list-style: none;
  margin-left: 25px;
}

.menu-items {
  margin-left: 10px;
  margin-right: 10px;
  list-style: none;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 5px;
}

.menu-items a {
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  color: #000000 !important;
}

.menu-items:hover,
.menu-items:focus {
  background-color: #0000003b;
  border-radius: 8px;
}

.menu-items.active {
  background-color: #0000003b;
  border-radius: 8px;
}

.menu-items i {
  padding: 10px;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 15px;
}

.side .menu-items a span {
  color: #ffffff !important;
  display: inline-flex;
  margin: 0px !important;
}

.side .menu-items.active a span {
  color: #ffffff !important;
}

.white-icon {
  filter: invert(100%);
}

.hr-line-container {
  width: 100%;
  border-bottom: 1px solid #ffffff;
  margin-bottom: 20px;
}

/* Submenu styles */
.side .submenu-dropdown {
  display: block !important;
  padding-left: 0px;
}

.side.menu-items:hover .submenu-dropdown {
  display: block !important;
}

.side .submenu-dropdown .menu-items {
  border-left: 1px #5e5d5d solid;
  padding: 5px 0px;
  padding-left: 10px;
  margin-left: 51px;
}

.side .submenu-dropdown .menu-items {
  list-style: none;
}

.side .submenu-dropdown .menu-items a span {
  font-size: 14px;
}

.side .submenu-dropdown .menu-items:hover,
.side .submenu-dropdown .menu-items:focus,
.side .submenu-dropdown .menu-items.active {
  background-color: #0000003b !important;
  border-radius: 0 8px 8px 0 !important;
}
.dropdown-item {
  width: 200px;
  padding: 8px 16px;
  margin: 0;
}

.dropdown-item:hover {
  background-color: #9ca3af;
  color: #000;
}
.dropdown-item {
  width: 100%;
  text-align: left;
}
.divider {
  border-bottom: 1px solid #ccc;
  margin: 0px 0;
}